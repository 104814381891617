import React from 'react';
import {
  Box,
  Container
} from '@chakra-ui/react';
import Seo from '../../components/sections/seo';
import Bio from '../../components/sections/bio';

// import BioWithRightMedia from '../../components/sections/biowithrightmedia';
// import EVPMountainHardwear from '../../components/ui/ourcompany/leadership/TROY_SICOTTE_MHW_HEADSHOT-scaled.jpeg'

export default function MountainHardwearTabPanel() {
  return (
    <Box>
      <Seo
        title="Leadership at Mountain Hardwear"
        description="Executive leadership at Mountain Hardwear."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
      <Container
        maxW={'80ch'}
        marginX={'auto'}
        paddingX={'clamp(1.09rem, 1rem + 0.47vw, 1.33rem)'}
        sx={{'--flow-lh': '1.6'}}
        marginY={'12'} 
        className='flow'
      >
        {/* <BioWithRightMedia
          title={'TROY SICOTTE'}
          subTitle={'PRESIDENT, MOUNTAIN HARDWEAR'}
          bio={
              <Text>
                Troy Sicotte joined Mountain Hardwear in 2018 as Vice President of
                Sales and was named President of the Mountain Hardwear brand in
                September 2021. Prior to joining Mountain Hardwear, Mr. Sicotte
                served as Global Director of Sales at 034Motorsport. He held
                several leadership roles at The North Face, including Director of
                Sales: Footwear and Director of Sales: National Outdoor & Sporting
                Goods, and began his career at Birkenstock.
              </Text>
          }
          media={
            <Image
              borderRadius={10}
              src={EVPMountainHardwear}
            />
          }
        /> */}

        <Bio
          name={'Troy Scicotte'}
          title={'President, Mountain Hardwear'}
          bio={
            <p>
              Troy Sicotte joined Mountain Hardwear in 2018 as Vice President of Sales and was named President of the Mountain Hardwear brand in September 2021. Prior to joining Mountain Hardwear, Mr. Sicotte served as Global Director of Sales at 034Motorsport. He held several leadership roles at The North Face, including Director of Sales: Footwear and Director of Sales: National Outdoor & Sporting Goods, and began his career at Birkenstock.
            </p>
          }
        />
      </Container>
    </Box>
  );
}

import React from 'react';
import {
  Box, Container,
} from '@chakra-ui/react';
import Seo from '../../components/sections/seo';
import Bio from '../../components/sections/bio';

// import BioWithRightMedia from '../../components/sections/biowithrightmedia';
// import PresidentSorel from '../../components/ui/ourcompany/leadership/cory_long_headshot.jpg'

export default function SorelTabPanel() {
  return (
    <Box>
        <Seo
          title="Leadership at SOREL"
          description="Executive leadership at SOREL."
          //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
        />
      <Container
        maxW={'80ch'}
        marginX={'auto'}
        paddingX={'clamp(1.09rem, 1rem + 0.47vw, 1.33rem)'}
        sx={{'--flow-lh': '1.6'}}
        marginY={'12'} 
        className='flow'
      >
        {/* <BioWithRightMedia
            title={'CORY LONG'}
            subTitle={'PRESIDENT, SOREL'}
            bio={
                <Text>
                 Cory Long joined SOREL as President of the SOREL brand in December 2023. Prior to joining SOREL, Mr. Long served as Global General Manager and Chief Global Merchant of DC Shoes. He has held leadership roles at an array of brands, including Mitchell & Ness, Asics, Supra, and Converse.
                </Text>
            }
            media={
              <Image borderRadius={10} src={PresidentSorel} />
            }
          /> */}

        <Bio
          name={'Cory Long'}
          title={'President, SOREL'}
          bio={
            <p>
              Cory Long joined SOREL as President of the SOREL brand in December 2023. Prior to joining SOREL, Mr. Long served as Global General Manager and Chief Global Merchant of DC Shoes. He has held leadership roles at an array of brands, including Mitchell & Ness, Asics, Supra, and Converse.
            </p>
          }
        />
       </Container>
    </Box>
  );
}

export default function PageHeader({ bkgImg, bkgImgMobile, heading, subheading }) {
    return (
        <section className='hero page-banner'>
            <div className='hero__image-wrapper'>
                <picture>
                    {bkgImgMobile ? (<>
                        <source media="(min-width: 768px)" srcset={bkgImg} />
                        <img className="hero__bkg-image" src={bkgImgMobile} alt="" />
                        </>) : (
                        <img className="hero__bkg-image" src={bkgImg} alt="" />
                    )}
                </picture>
                
            </div>
            <div className='hero__container'>
                <div className='hero__wrapper'>
                    <div className='hero__row'>
                        <div className='hero__content-container'>
                            <div className='hero__content'>
                                <h1>{ heading }</h1>
                                {subheading ? (<p>{subheading}</p>) : null }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hero-main__overlay"></div>
        </section>
  );
}
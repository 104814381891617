import React from 'react';
import {
    Image,
    Flex,
    useBreakpointValue,
    VStack,
    Text,
    Stack,
    Heading,
    AspectRatio,
    SimpleGrid,
    Link,
    Container,
  } from '@chakra-ui/react';
import HomeLayout from '../../../components/layouts/homelayout';
import Seo from '../../../components/sections/seo';

import scTransparencyHeroImg from '../../../components/ui/corporateresponsibility/responsiblepractices/sctransparency/sc_transparancy_page.png'

export default function SupplyChain() {
    return (
        <HomeLayout>
            <Seo
                title="Corporate Responsibility - Supply Chain Transparency Map"
                description="Utilizing measurement tools and systems allows us to be transparent about our practices to stakeholders. "
                //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
            />
            <Flex
                w={'full'}
                h={'60vh'}
                className='page-banner'
                backgroundImage={scTransparencyHeroImg}
                backgroundSize={'cover'}
                backgroundPosition={'center center'}
                id="scTransparencyHeroImg"
                title={`Supply Chain Transparency Map`}
            >
                <VStack
                    w={'full'}
                    justify={'flex-end'}
                    align={'center'}
                    px={useBreakpointValue({ base: 4, md: 8 })}
                    bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
                    >
                    <Stack w={'full'} maxW={'6xl'} spacing={6} px={4}>
                        <Heading
                            align="right"
                            color={'white'}
                            fontWeight={600}
                            lineHeight={1.2}
                            fontSize={useBreakpointValue({
                                base: '2xl',
                                md: '4xl',
                                sm: '3xl',
                            })}
                            mb="10px"
                            id="responsiblepracticestext"
                            >
                                <Text as="span" 
                                    fontSize={useBreakpointValue({
                                        base: 'xl',
                                        md: '3xl',
                                    })}
                                    >
                                    Responsible Practices: <br />
                                </Text>
                            Our Supply Chain Transparency Map
                        </Heading>
                    </Stack>
                </VStack>
            </Flex>
            <Container maxW="6xl" my={9} className='flow'>
                <Text>
                    Utilizing measurement tools and systems allows us to be transparent about our practices to stakeholders. To this end, we are proud to provide an annual <Link href='/corporate-responsibility-group/'>Corporate Responsibility Report</Link> to share our yearly accomplishments and initiatives, progress towards goals, as well as challenges we face.
                </Text>
                <Heading as="h2">
                    Transparency Map
                </Heading>  
                <Text>
                    Columbia is committed to supply chain transparency. To demonstrate transparency, we maintain a Factory Transparency Map that provides visibility to where Columbia Sportswear Company (including Columbia, Mountain Hardwear, SOREL and prAna) products are made. To do this we have partnered with the <Link href='https://info.opensupplyhub.org/mission' isExternal>Open Supply Hub (OSH)</Link> to publish an interactive map.
                </Text>
                <Text>
                    Since 2013, we have been publicly reporting our finished goods factories (Tier 1). In 2022, we expanded the reporting to include more factories in the supply chain, including a growing number of our processing factories (Tier 2), as well as additional factory data points shown on each factory profile. At the time of the last update, this map represents 99% of our finished goods factories supply chain. The total processing factories on the map account for about 80% of our total business volume.
                </Text>
                <Text>
                    This map provides an interactive overview of the factories who are actively producing products for our brands. Additionally, the transparency map highlights some key initiatives highlighted on a factory’s profile. These key initiatives focus on creating positive impacts in our supply chain through collaboration with our factories: <Link href='https://www.bluesign.com/en' isExternal>bluesign</Link>, <Link href='https://apparelimpact.org/apparel-impact-institute-carbon-leadership-project/' isExternal>Carbon Leadership Project</Link>, <Link href='https://apparelimpact.org/program-areas/' isExternal>Clean by Design</Link>, <Link href='https://www.fairtradecertified.org/' isExternal>Fair Trade Certification</Link>, <Link href='https://riseequal.org/our-focus-areas/' isExternal>RISE</Link>, <Link href='https://www.columbia.com/her_project_pluscolsportswear.html' isExternal>HERproject</Link>, <Link href='https://www.leatherworkinggroup.com/' isExternal>Leather Working Group</Link>, <Link href='https://www.columbia.com/planet_water_foundation.html' isExternal>Planet Water Foundation</Link>, and <Link href='https://textileexchange.org/standards/responsible-down/' isExternal>Responsible Down Standard</Link>.
                </Text>
                <Text>
                    In the dropdown under the field Filter by Contributor List, you can select one or multiple of our brands’ supply chain to visualize and search. Selecting the list Columbia Sportswear Company (CSC) will return all reported factories in our company’s portfolio.
                </Text>
                <Text>
                    To download a machine-readable format of the factory list, select facilities on the map and then download. The transparency map is updated semi-annually to reflect changes in our source base. The map was last updated in July 2024. If you have any questions about this map and/or the data, please reach out to <a href="mailto:cr@columbia.com">cr@columbia.com</a>.
                </Text>
                <AspectRatio ratio={16 / 9} my={9}>
                    <iframe
                        title='Supply Chain Transparency Map'
                        src='https://opensupplyhub.org/facilities?contributors=2340&embed=1'
                    />
                </AspectRatio>
                <Heading as="h2">
                    Where Your Products are Made
                </Heading>
                <Text>
                    For Columbia, Mountain Hardwear and SOREL products, consumers can use this map to search for the factory that made the product (this feature is not available on licensed and prAna products). Each factory has a unique CSC factory ID, a 2-5 digit number, which is incorporated into the care label for apparel, equipment, and accessories and in the tongue label for footwear. See below for an example of a label. If the ID you are searching for does not return a factory profile in the map, then it may be that we are currently not producing with that factory.
                </Text>
                <SimpleGrid columns={2} gap={3}>
                    <Flex align="center" justify="center">
                        <Image w={{base:'100%', md: '50%', }} src='/images/CSC_SupplyChain_Apparel-Tag.jpg'></Image>
                    </Flex>
                    <Flex align="center" justify="center">
                        <Image w={{base:'100%', md: '50%', }} src='/images/CSC_SupplyChain_Footwear-Tag.jpg'></Image> 
                    </Flex>
                </SimpleGrid> 

            </Container>
            
            
        </HomeLayout>

    );
}
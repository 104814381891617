// import {} from '@chakra-ui/react';

export default function Bio({ name, title, bio }) {
  return (
    <div className='bio flow'>
        <header>
            <h3>{ name }</h3>
            <p>{ title }</p>
        </header>
        <div className='bio-text flow'>
            { bio }
        </div>
    </div>

  );
}

import React from 'react';
import { Box, SimpleGrid, Flex, Image, Container, Card, CardBody, Link, UnorderedList, ListItem } from '@chakra-ui/react';

import HomeLayout from '../../components/layouts/homelayout';
import Seo from '../../components/sections/seo';

import CtaShort from '../../components/sections/ctashort';
import CarouselStories from '../../components/sections/carouselstories';
import GridCard from '../../components/sections/gridcard';

// Get the logos
import columbiaLogo from '../../components/ui/brandlogos/columbia_logo.png';
import sorelLogo from '../../components/ui/brandlogos/sorel_logo.png';
import mhwLogo from '../../components/ui/brandlogos/mhw_logo.png';
import pranaLogo from '../../components/ui/brandlogos/prana_logo.png';

// Get the background images
import mhwBkgImg from '../../components/ui/globalcareers/fourbrand/mhw_bkg.jpg';
import pranaBkgImg from '../../components/ui/globalcareers/fourbrand/prana_bkg.jpg';
import columbiaBkgImg from '../../components/ui/globalcareers/fourbrand/columbia_bkg.jpg';
import sorelBkgImg from '../../components/ui/globalcareers/fourbrand/sorel_bkg.jpg';

// Impact Report images
import ir2015 from '../../components/ui/corporateresponsibility/impact/CSC_SR_2015_cover.jpg';
import ir2016 from '../../components/ui/corporateresponsibility/2016_impact-report_cover.png';
import ir2017 from '../../components/ui/corporateresponsibility/2017_impact-report_cover.png';
import ir2018 from '../../components/ui/corporateresponsibility/2018_impact-report_cover.png';
import ir2019 from '../../components/ui/corporateresponsibility/2019_impact-report_cover.png';
import ir2020 from '../../components/ui/corporateresponsibility/2020_impact-report_cover.png';
import ir2021 from '../../components/ui/corporateresponsibility/2021_impact-report_cover.png';
import ir2022 from '../../components/ui/corporateresponsibility/2022_impact-report_cover.png';

// Other image assets
import impactBadge from '../../components/ui/corporateresponsibility/impact/impact_report_badge.svg';
import planetWaterImg from '../../components/ui/corporateresponsibility/impact/stories/planet_water.jpg';
import transitionProjectsImg from '../../components/ui/corporateresponsibility/impact/stories/transition-projects.jpg';
import club1938Img from '../../components/ui/corporateresponsibility/impact/stories/club-1938.jpg';
import mhwRepairImg from '../../components/ui/corporateresponsibility/impact/stories/mhw-repair.jpg';
import collegiateOutdoorImg from '../../components/ui/corporateresponsibility/impact/stories/collegiate-outdoor.jpg';

import empoweringPeopleImg from '../../components/ui/corporateresponsibility/impact/empowering-people-highlight.jpg';
import sustainingPlacesImg from '../../components/ui/corporateresponsibility/impact/sustaining-places-highlights.jpg';
import responsiblePracticesImg from '../../components/ui/corporateresponsibility/impact/responsible-practices-highlights.jpg';

export default function Impact() {

    const stories = [{
        id: 1,
        heading: 'Club 1938',
        image: `${club1938Img}`,
        description: 'Club 1938 is an outdoor program for our employees based in China, providing a virtual hub for sharing tips and ideas to increase knowledge about the outdoors.',
        url: '/corporate-responsibility/impact/stories/club-1938'
    },
    {
        id: 2,
        heading: 'Clean Drinking Water Program',
        image: `${planetWaterImg}`,
        description: 'Combatting the global water crisis and improving access to clean drinking water in areas where CSC products are produced through a partnership with Planet Water Foundation.',
        url: '/corporate-responsibility/impact/stories/clean-drinking-water-program'
    },
    {
        id: 3,
        heading: 'Transition Projects',
        image: `${transitionProjectsImg}`,
        description: 'For over 20 years, Columbia Sportswear has been a proud supporter of Transition Projects, providing employees with bi-weekly volunteer opportunities to serve meals to those experiencing houselessness in the Portland, Oregon area.',
        url: '/corporate-responsibility/impact/stories/transition-projects'
    },
    {
        id: 4,
        heading: 'Collegiate Outdoor Recreation Program',
        image: `${collegiateOutdoorImg}`,
        description: 'Columbia, prAna and Mountain Hardwear are proud to support the Collegiate Outdoor Recreation Program, providing students access to the quality clothing and equipment that’s needed to get outside safely and comfortably.',
        url: '/corporate-responsibility/impact/stories/collegiate-outdoor-recreation-program'
    },
    {
        id: 5,
        heading: 'Repair Services at Mountain Hardwear',
        image: `${mhwRepairImg}`,
        description: 'The Mountain Hardwear Warranty team ensures they are doing everything they can to extend the use of an item, whether it’s repairing a zipper or donating the product to a local non-profit.',
        url: '/corporate-responsibility/impact/stories/repair-services-at-mountain-hardwear'
    },
];

    return (
        <HomeLayout align="left">
            <Seo
                title="Impact Report"
                description="Columbia Sportswear Company's Impact Report demonstrates our commitment to ensuring the sustainability of our business and the planet."
                //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
            />
            <Box className='page-banner' w="full"
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                >
                <SimpleGrid columns={2}
                    gap={1}
                    padding={{ base: 0, md: 10 }}
                >
                     <Flex 
                        maxW="100%"
                        justify="flex-start"
                        align="flex-end"
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${columbiaBkgImg})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        pos={'relative'}
                        pb={'8px'}
                        pl={'8px'}
                        minH={'clamp(200px, 50vw, 400px)'}
                    >
                        <Image
                            src={columbiaLogo}
                            alt={'Columbia Logo'}
                            width={{ base: '50%', md: '40%' }}
                        />
                    </Flex>

                    <Flex 
                        maxW="100%"
                        justify="flex-end"
                        align="end"
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${sorelBkgImg})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        pb={'8px'}
                        pr={'8px'}
                    >
                        <Image
                            src={sorelLogo}
                            alt={'Sorel Logo'}
                            width={{ base: '30%', md: '25%' }}
                        />
                    </Flex>

                    <Flex 
                        maxW="100%"
                        //minH={{base: '150px', md: '325px'}}
                        justify="flex-start"
                        align="flex-end"
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${mhwBkgImg})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        //minH={'clamp(200px, 50vw, 633px)'}
                        minH={'clamp(200px, 50vw, 400px)'}
                        pb={'8px'}
                        pl={'8px'}
                    >
                        <Image
                            src={mhwLogo}
                            alt={'Mountain Hardwear Logo'}
                            width={{ base: '40%', md: '25%' }}
                        />
                    </Flex>

                    <Flex 
                        maxW="100%"
                        //minH={{base: '150px', md: '325px'}}
                        justify="flex-end"
                        align="end"
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${pranaBkgImg})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        pb={'8px'}
                        pr={'8px'}
                        //minH={'clamp(200px, 50vw, 633px)'}
                    >
                        <Image
                            src={pranaLogo}
                            alt={'prAna Logo'}
                            width={{ base: '40%', md: '26%' }}
                        />
                    </Flex>
                </SimpleGrid>
                <Image
                    src={impactBadge}
                    alt={'ESG Badge'}
                    pos={'absolute'}
                    w={{ base: '35%', md: '25%'}}
                />
            </Box>
            <Box 
                as='section'
                py={8}
                bgColor={'gray.100'}
            >
                <Container maxW={'7xl'}>
                    <h2>2023 Highlights</h2>
                    <Box
                        marginTop={6}
                    >
                        <SimpleGrid
                            columns={{ base: 1, sm: 2, md: 3}}
                            spacing={{ base : 8, md: 4 }}
                        >
                            <Box>
                                <Box 
                                    className='highlights-image'
                                    display={'flex'}
                                    justifyContent={'center'}
                                >
                                    <Image src={empoweringPeopleImg} borderRadius={'50%'} width={'75%'} />
                                </Box>
                                <Box 
                                    className='highlights-text'
                                    padding={4}
                                    mt={1}    
                                >
                                    <h3>Empowering People</h3>
                                    <UnorderedList mt={3}>
                                        <ListItem>In addition to the 36 global water towers we’ve installed since 2016, we built our first water tower in Central America in partnership with Planet Water</ListItem>
                                        <ListItem>Over 6,000 volunteer hours were logged by employees</ListItem>
                                        <ListItem>Mountain Hardwear launched a new partnership with Leave No Trace, an organization promoting conservation in the outdoors</ListItem>
                                        <ListItem>Introduced new time-off policies for U.S. employees</ListItem>
                                        <ListItem>Wellness reimbursement program introduced for U.S. employees</ListItem>
                                    </UnorderedList>
                                </Box>
                            </Box>
                            <Box>
                                <Box 
                                    className='highlights-image'
                                    display={'flex'}
                                    justifyContent={'center'}
                                >
                                    <Image src={sustainingPlacesImg} borderRadius={'50%'} width={'75%'} />
                                </Box>
                                <Box 
                                    className='highlights-text'
                                    padding={4}    
                                >
                                    <h3>Sustaining Places</h3>
                                    <UnorderedList mt={3}>
                                        <ListItem>10<sup>th</sup> Year of completing the Higg Index Facilities Environmental Assessment; increased overall Higg scores to 14% above industry averages</ListItem>
                                        <ListItem>Completed our first procurement of Green-e<sup>®</sup> certified Renewable Energy Certificates</ListItem>
                                        <ListItem>Maintained 100% Responsible Down Standard certified down and Leather Working Group certified tannery commitments</ListItem>
                                        <ListItem>Invested in new software systems to improve Greenhouse Gas accounting and product footprinting capabilities</ListItem>
                                    </UnorderedList>
                                </Box>
                            </Box>
                            <Box>
                                <Box 
                                    className='highlights-image'
                                    display={'flex'}
                                    justifyContent={'center'}
                                >
                                    <Image src={responsiblePracticesImg} borderRadius={'50%'} width={'75%'} />
                                </Box>
                                <Box 
                                    className='highlights-text'
                                    padding={4}    
                                >
                                    <h3>Responsible Practices</h3>
                                    <UnorderedList mt={3}>
                                        <ListItem>Connected more than 101,000 workers in our supply chain to RISE programs, since the start of our RISE (formerly HERproject) partnership in 2008</ListItem>
                                        <ListItem>79% of suppliers rated a B or above on our Social Responsibility Supplier rating</ListItem>
                                        <ListItem>Continued our commitment to transparency publishing our Tier 1 and Tier 2 supplier list for the third year in a row</ListItem>
                                    </UnorderedList>
                                </Box>
                            </Box>

                        </SimpleGrid>
                    </Box>

                </Container>
            </Box>
            <Box>
                <CtaShort 
                    button_text={'Read the 2023 Impact Report'}
                    button_url={'https://d1io3yog0oux5.cloudfront.net/_7243294f6152cc533d813bdcb6c832fb/columbia/db/641/5807/pdf/Columbia_Impact_Report_2023.pdf'}
                    backgroundColor={'var(--columbia-blue);'}
                    secondary_text={'2023 SASB Summary'}
                    secondary_url={'https://nam10.safelinks.protection.outlook.com/?url=https%3A%2F%2Fd1io3yog0oux5.cloudfront.net%2F_01fea794d7296aa7843c69c83dbde8e8%2Fcolumbia%2Fdb%2F641%2F5806%2Fpdf%2FColumbia_SASB_Index_2023.pdf&data=05%7C02%7CNick.Smith%40columbia.com%7Cfb970d2423694b78d1db08dcbd6e3310%7Cbacbd475b9da43fa958ced27e9d42d0e%7C0%7C0%7C638593528224228785%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=xhFcIiXYzZnn6TFjef79NydwocgJIk7O%2B8YSBtwEmIs%3D&reserved=0'}
                />
            </Box>
            <Box 
                as='section'
                mt={14}
            >
                <Container maxW='7xl' className='flow'>
                    <h2>Latest Stories</h2>
                    <CarouselStories cards={stories}></CarouselStories>
                </Container>
            </Box>
            <Box 
                as='section'
                mt={6}
            >
                <Container maxW='7xl' my={14} className='flow'>
                    <h2>Historic Impact Reports</h2>
                    <SimpleGrid
                        align={'center'}
                        columns={{ base: 1, md: 2, lg: 3 }}
                        spacing={4}
                        py={4}
                        gap={12}
                    >
                        <Card variant='unstyled'>
                            <CardBody>
                            <GridCard
                                title="2022 Report"
                                href="https://d1io3yog0oux5.cloudfront.net/_dd23717e8b1245210477340f7aff112a/columbia/db/641/5779/pdf/2022+Impact+Report.pdf"
                                backgroundImage={ir2022}
                                type='is-medium three-column'
                            />
                            <Link display='inline-block' mt={3} href='https://d1io3yog0oux5.cloudfront.net/_dd23717e8b1245210477340f7aff112a/columbia/db/641/5780/pdf/2022+Sustainability+Accounting+Standards+Board+Index.pdf' isExternal>
                                2022 SASB Summary
                            </Link>
                            </CardBody>
                        </Card>
                        <Card variant='unstyled'>
                            <CardBody>
                            <GridCard 
                                title="2021 Report"
                                href="https://s3.amazonaws.com/content.stockpr.com/columbia/db/718/5753/pdf/Columbia_CSR_RY2021_FINAL_.pdf"
                                backgroundImage={ir2021}
                                type='is-medium three-column'
                            />
                            <Link display='inline-block' mt={3} href='https://investor.columbia.com/2021_sasb_index' isExternal>
                                2021 SASB Summary
                            </Link>
                            </CardBody>
                        </Card>
                        <Card variant='unstyled'>
                            <CardBody>
                            <GridCard
                                title="2020 Report"
                                href="https://d1io3yog0oux5.cloudfront.net/_258e51144a7b70f3536dfd884976988a/columbia/db/641/5719/pdf/2020+Corporate+Responsibility+Report.pdf"
                                backgroundImage={ir2020}
                                type='is-medium three-column'
                            />
                            <Link display='inline-block' mt={3} href='https://d1io3yog0oux5.cloudfront.net/_258e51144a7b70f3536dfd884976988a/columbia/db/641/5720/pdf/2020+Sustainability+Accounting+Standards+Board+Index.pdf' isExternal>
                                2020 SASB Summary
                            </Link>
                            </CardBody>
                        </Card>
                        <Card variant='unstyled'>
                            <CardBody>
                            <GridCard
                                title="2019 Report"
                                href="https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/CR_Reports/2019_Columbia_Corp_Resp_Report.pdf"
                                backgroundImage={ir2019}
                                type='is-medium three-column'
                            />
                            </CardBody>
                        </Card>
                        <Card variant='unstyled'>
                            <CardBody>
                            <GridCard
                                title="2018 Report"
                                href="https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/CR_Reports/2018_Columbia_Corp_Resp_Report.pdf"
                                backgroundImage={ir2018}
                                type='is-medium three-column'
                            />
                            </CardBody>
                        </Card>
                        <Card variant='unstyled'>
                            <CardBody>
                            <GridCard
                                title="2017 Report"
                                href="https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/CR_Reports/2017_Columbia_Corp_Resp_Report.pdf"
                                backgroundImage={ir2017}
                                type='is-medium three-column'
                            />
                            </CardBody>
                        </Card>
                        <Card variant='unstyled'>
                            <CardBody>
                            <GridCard
                                title="2016 Report"
                                href="https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/CR_Reports/2016_Columbia_Corp_Resp_Report.pdf"
                                backgroundImage={ir2016}
                                type='is-medium three-column'
                            />
                            </CardBody>
                        </Card>
                        <Card variant='unstyled'>
                            <CardBody>
                            <GridCard
                                title="2015 Report"
                                href="https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/CR_Reports/2015_Columbia_Corp_Resp_Report.pdf"
                                backgroundImage={ir2015}
                                type='is-medium three-column'
                            />
                            </CardBody>
                        </Card>
                    </SimpleGrid>
                </Container>
            </Box>
        </HomeLayout>
    );
}
import React from 'react';
import { Heading, Text, VStack, } from '@chakra-ui/react';

export default function TextBox({ title, subTitle, textArea, ...props }) {
  return (

      <VStack
        align="left"
        direction={{ base: 'column', md: 'row' }}
        mt={'var(--space-m)'}
      >
        <Heading
          as={'h3'}
          fontSize={"xl"}
          fontWeight={600}
          align="left"
          lineHeight={1}
        >
          {title}
        </Heading>
        <Text
          fontSize={"l"}
          fontWeight={600}
          align="left"
          lineHeight={1}
          sx={{ '--flow-lh': '1' }}
        >
          {subTitle}
        </Text>
        <Text fontSize="sm" mt={'var(--space-2xs)'}>{textArea}</Text>
      </VStack>

  );
}

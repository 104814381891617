import { Box, Stack, Flex } from '@chakra-ui/react';

export default function TextWithRightMedia({ title, content, media, cta_text, cta_url, is_full_bleed }) {
  return (
    <Box
        as='section'
        className={`${ is_full_bleed ? "full-bleed" : ""}`}
    >
      <Stack
        align="center"
        spacing={{ base: 10, md: 20 }}
        py={{ base: 5, md: 10 }}
        direction={{ base: 'column', md: 'row' }}
        px={{base: 0, md: 10}}>
        <Box
            className='flow' 
            flex={1}
            px={{ base: 5, md: 0 }}
            order={{ base: 2, md: 1 }}
        >
            { title }
            { content }

            {cta_url ? (
              <Box 
                  as='a'
                  className='btn btn-black btn-normal pill-border'
                  textAlign={'center'}
                  href={cta_url}
                  target='_blank'
                  display={'inline-block'}
              >
                  {cta_text}
              </Box>
            ) : (
              null
            )}
                
        </Box>
        <Flex
          flex={1}
          justify="center"
          align="center"
          position="relative"
          w="full"
          order={{ base: 1, md: 2 }}
          >
          { media }
        </Flex>
      </Stack>
    </Box>
  );
}

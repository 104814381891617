import React from 'react';
import {
    Box,
    Button,
    Container,
    Heading,
    Icon,
    Image,
    Link,
    LinkBox,
    LinkOverlay,
    ListItem,
    SimpleGrid,
    Text,
    UnorderedList,
    VStack
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Seo from '../../components/sections/seo';

import { CSCPolicies, PosterItems, PSListItems } from '../../components/ui/corporateresponsibility/responsiblepractices/lists';
import HeroWithLeftMedia from '../../components/sections/herowithleftmedia';
import HeroWithRightMedia from '../../components/sections/herowithrightmedia';
import HeroWithTopMedia from '../../components/sections/herowithtopmedia';

import MHW from '../../components/ui/corporateresponsibility/responsiblepractices/MHW.jpg';
import ODIPhoto from '../../components/ui/corporateresponsibility/responsiblepractices/ODIphoto.jpg';
import OrganizationImages from '../../components/ui/corporateresponsibility/responsiblepractices/OrganizationImages';
import SorelSandals from '../../components/ui/corporateresponsibility/responsiblepractices/SorelRedSandals.jpg';
import elevatingResponsibility from '../../components/ui/corporateresponsibility/responsiblepractices/elevating-responsibility.jpg';
import leveragingInnovation from '../../components/ui/corporateresponsibility/responsiblepractices/leveraging-innovation.jpg';
import scTransparency from '../../components/ui/corporateresponsibility/responsiblepractices/supply-chain-transparency.png';


export default function ResponsiblePractices() {
    return (
        
        <Box>
            <Seo
                title="Corporate Responsibility - Responsible Practices"
                description="Key practices that elevate Corporate Responsibility."
                //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
            />
            <Container maxW={'6xl'}>
                <Heading as={'h1'} mt={5}>Responsible Practices</Heading>
                <SimpleGrid align={'center'} columns={{ base: 1, md: 2, lg: 3 }} spacing={12} mb={16}>
                    <HeroWithTopMedia
                        title={'Supply Chain Transparency Map'}
                        subTitle={'Our transparency map provides visibility to where products made by Columbia Sportswear Company brands are produced. We utilize the OSH tool to enable us to be transparent to stakeholders.'}
                        cta={
                            <LinkBox as={'a'} mt={'auto'}>
                                <LinkOverlay
                                    href='/corporate-responsibility-group/responsible-practices/supply-chain'
                                    >
                                        <Button
                                            size="md"
                                            p={6}
                                            bg="black"
                                            color="white"
                                            borderRadius={8}
                                            >
                                            LEARN MORE
                                            <Icon
                                                color="white"
                                                w={5}
                                                h={5}
                                                as={ExternalLinkIcon}
                                                pl="4px"
                                                ml={1}
                                            >
                                            </Icon>
                                        </Button>
                                </LinkOverlay>
                            </LinkBox>
                        }
                        media={
                        <Image width="100%" borderRadius={10} src={scTransparency} />
                        }
                    ></HeroWithTopMedia>
                    <HeroWithTopMedia
                        title="Leveraging Innovation & Design to Create Social & Environmental Value"
                        subTitle={'Driving our core competencies of innovation and design across our portfolio of brands to create social and environmental value. In doing this, we aim to increase consumer loyalty and develop a strong connection to our brands.'}
                        cta={        
                            <LinkBox as={'a'} mt={'auto'}>
                                <LinkOverlay
                                    href='/corporate-responsibility-group/responsible-practices/leveraging-innovation'
                                    >
                                        <Button
                                            size="md"
                                            p={6}
                                            bg="black"
                                            color="white"
                                            borderRadius={8}
                                            >
                                            LEARN MORE
                                            <Icon
                                                color="white"
                                                w={5}
                                                h={5}
                                                as={ExternalLinkIcon}
                                                pl="4px"
                                                ml={1}
                                            >
                                            </Icon>
                                        </Button>
                                </LinkOverlay>
                            </LinkBox>
                        }
                        media={
                        <Image
                            borderRadius={10}
                            src={leveragingInnovation}
                        />
                        }
                    ></HeroWithTopMedia>
                    <HeroWithTopMedia
                        title="Elevating Responsibility, Integrity and Compliance"
                        subTitle={'Promoting responsible manufacturing by selecting vendors that have demonstrated commitments to continuously improve their Social Responsibility, Environmental and Product Compliance metrics.'}
                        cta={
                            <LinkBox as={'a'} mt={'auto'}>
                                <LinkOverlay
                                    href='/corporate-responsibility-group/responsible-practices/elevating-responsibility'
                                    >
                                        <Button
                                            size="md"
                                            p={6}
                                            bg="black"
                                            color="white"
                                            borderRadius={8}
                                            >
                                            LEARN MORE
                                            <Icon
                                                color="white"
                                                w={5}
                                                h={5}
                                                as={ExternalLinkIcon}
                                                pl="4px"
                                                ml={1}
                                            >
                                            </Icon>
                                        </Button>
                                </LinkOverlay>
                            </LinkBox>
                        }
                        media={<Image borderRadius={10} src={elevatingResponsibility} />}
                    ></HeroWithTopMedia>
                </SimpleGrid>
            </Container>
            
            <Container maxW={'6xl'}>
                <Text
                    fontSize={"3xl"} 
                    fontWeight={"bold"}
                    fontFamily={"GerTT"}
                    >
                    COLLABORATION AND ENGAGEMENT
                </Text>
                <Text lineHeight={7}>
                    We recognize that we are a single player in a large, complex, global supply chain. To impact meaningful change, we need
                    to work with brand, industry, and multi-stakeholder partners to:
                    <UnorderedList spacing={3} p={10}  >
                        <ListItem>
                            Align on common industry standards for evaluating our business and our supply chain. Standardization will 
                            enable greater understanding of social and environmental impacts as well as increased efficiency in evaluation 
                            methods and broader comparability of results. Industry standards allow us to get on the same page about 
                            possible impacts and support our collaborative work towards shared goals around social responsibility, 
                            environmental sustainability and product compliance.
                        </ListItem>
                        <ListItem>
                            Develop shared tools to reduce costs and improve implementation of social, environmental, and compliance programs
                        </ListItem>
                        <ListItem>
                            Share resources with industry partners on specific projects to increase collective impact.
                        </ListItem>
                    </UnorderedList>
                </Text>
                <Text lineHeight={7} >
                    We select organizations for investment and engagement based on their ability to influence systemic change and their 
                    relevance to our business and supply chain. We also seek to work directly with other brands and retailers who share 
                    our values and whose products are made in the same factories as ours. Click on the organization below to learn more.
                </Text>
                <SimpleGrid p={20} columns={{ base: 1, md: 3 }} columnGap={'var(--space-m)'} rowGap={'var(--space-xl)'} align="center">
                    {OrganizationImages.map((image, index) => (
                        <Link key={index} href={image.href} alignSelf={'center'} isExternal>
                            <Image src={image.src} width="200px" p={2}/>
                        </Link>
                    ))}
                </SimpleGrid>
            </Container>
            <HeroWithLeftMedia media={
                    <>
                        <VStack spacing={2} alignItems="start" >
                            <Image borderRadius={10} src={ODIPhoto} /> 
                            <Text > Photo Credit: Outdoor Industry Association </Text>
                        </VStack>
                    </>
                }
                title={<Text fontWeight={"bold"} mb={4} fontFamily={"GerTT"} fontSize="md"> POSITION STATEMENTS </Text>}
                subTitle={
                    <>
                        <Text mt={-6}> We value ethical practices at every step in the supply chain and work with industry groups, civil society organizations and government organizations to keep educated on ethical standards and practices across the supply chain. Read more about Columbia’s specific positions on: </Text>
                        <UnorderedList ml={12} mt={4} fontSize="sm" spacing={2}>
                            {PSListItems.map((item, index) => (
                                <ListItem key={index}>
                                    <Link href={item.link} isExternal > 
                                        <Text textDecoration={"underline"}>{item.title}</Text>
                                    </Link>
                                </ListItem>
                            ))}
                        </UnorderedList>
                    </>
                }/>
            <HeroWithRightMedia media={<Image borderRadius={10} p={4} src={SorelSandals} />}
                title={<Text fontSize="md" fontFamily={"GerTT"} fontWeight={"bold"} ml={25} > PRODUCT COMPLIANCE STANDARDS, POLICIES AND MANUALS </Text>}
                subTitle={
                    <>
                        <UnorderedList ml={12} spacing={2} fontSize="sm">
                            {CSCPolicies.map((item, index) => (
                                <ListItem key={index}>
                                    <Link href={item.link} isExternal > 
                                        <Text textDecoration={"underline"}>{item.policyName}</Text>
                                    </Link>
                                </ListItem>
                            ))}
                        </UnorderedList>
                    </>
                }/>
            <HeroWithLeftMedia media={<Image mt={-6}  borderRadius={10} p={10} src={MHW} />}
                title={<Text fontWeight={"bold"} fontFamily={"GerTT"} fontSize="md"> SOCIAL RESPONSIBILITY STANDARDS, POLICIES AND MANUALS </Text>}
                subTitle={
                    <>
                        <Text mt={-4} mb="10px"> Our Standards of Manufacturing Practices (SMP), a.k.a. Supplier Code of Conduct, is at the center of our Social Responsibility Program in our supply chain. </Text>
                        <UnorderedList ml={12} mb={10} fontSize="sm" spacing={2}>
                            {PosterItems.map((item, index) => (
                                <ListItem key={index}>
                                    <Link href={item.link} isExternal > 
                                        <Text textDecoration={'underline'}>{item.poster}</Text>
                                    </Link>
                                </ListItem>
                            ))}
                        </UnorderedList>
                    </>
                }/>
        </Box>
    );
};
import React from 'react';
import { Container, Box, Image, OrderedList, ListItem, Text } from '@chakra-ui/react';

import Seo from '../../../components/sections/seo';
import TextWithRightMedia from '../../../components/sections/textwithrightmedia';
import TextWithLeftMedia from '../../../components/sections/textwithleftmedia';

import pranaPortraitImage from '../../../components/ui/ourbrands/prana/prana_portrait.jpg';
import pranaHQImage from '../../../components/ui/ourbrands/prana/prana_hq.jpg';
import pranaRetailImage from '../../../components/ui/ourbrands/prana/prana_retail.jpg';


export default function PranaTabPanel() {
  return (
    <Box>
      <Seo
        title="Careers at prAna"
        description="Find your next career at prAna."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
      <Container maxW='7xl' my={14} className='flow'>
        <Box 
          as={'section'}
          className='flow'
          textAlign={'center'}>
          <h1>
              Hey, we’re prAna.
          </h1>
          <Text
            className={'mw-prose'}
            mx={'auto'}
          >
            We create apparel for those whose lifestyle doesn’t fit the mold. From makers to explorers, climbers to yogis, prAna was born in Southern California but is lived in across the globe. 
          </Text>
          <Text
            className={'mw-prose'}
            mx={'auto'}
          >
            However you move, we want you to move with intention – to head towards what’s next even if you’re not sure what that is. If next for you might include adding your skillset to our team – we’d love to get to know you.
          </Text>
          <Box 
              as='a'
              className='btn btn-black btn-normal pill-border'
              textAlign={'center'}
              display={'inline-block'}
              href='https://columbiasportswearcompany.wd5.myworkdayjobs.com/prAna_Careers?source_id=csccom&source_p=careers_pra'
              target='_blank'
              sx={{'--flow-space': 'var(--space-l)'}}
              minW={'30%'}
          >
                    See Current Openings
                </Box>
        </Box>
        
        <TextWithRightMedia
          title={<h3>At prAna, we’re proud to</h3>} 
          media={<Image width={'75%'} src={pranaPortraitImage} />}
          is_full_bleed={true}
          content={
            <>
            <OrderedList spacing={5}>
              <ListItem>
                <Text>
                  <strong>Build for Purpose:</strong> We create everything to solve a specific problem. 
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <strong>Create, Creatively:</strong> We approach challenges with curiosity and stay open to new possibilities while searching for new solutions. 
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <strong>Lead with Authenticity:</strong> We make decisions based on our values and being transparent in our interactions and designs.
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <strong>Be More, Use Less:</strong> We strive to deliver products that the prAna community loves with the goal of minimizing environmental impact. We define environmental impact as protecting our natural resources, reducing greenhouse gas emissions, and creating quality and durable products. 
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <strong>Cultivate Community:</strong> We foster an environment where team members feel valued, supported, and connected to one another.
                </Text>
              </ListItem>
            </OrderedList>
            </>  
          }
        />

        <Box
          as='section'
          textAlign={'center'}
          sx={{'--flow-space': 'var(--space-xl)'}}
        >
          <h2>
            Come Work with Us 
          </h2>
          <Text>
            We have opportunities at both our HQ and retail locations.
          </Text>
        </Box>
        
        <TextWithLeftMedia 
          title={<h3>Carlsbad HQ</h3>}
          media={<Image src={pranaHQImage} />}
          is_full_bleed={true}
          cta_text={'HQ Openings'}
          cta_url='https://columbiasportswearcompany.wd5.myworkdayjobs.com/prAna_Careers?source_id=csccom&source_p=careers_pra'
          content={
            <>
            <Text>
              prAna started in 1992 in Carlsbad, California, and today, our HQ is still rooted here, so you’ll be close to the beach, mountains, and desert to enjoy outdoor activities year-round.
            </Text>
            <Text>
              Our office emphasizes community spaces with a new cafe and outdoor garden so you can get to know your co-workers outside of meeting rooms. We also have yoga every Wednesday at lunch to take a break, stretch and breathe.
            </Text>
            <Text>
              We spend time giving back to our community and have active partnerships with Un Mar de Colores, Casa de Amistad, and Outdoor Outreach.
            </Text>
            </>
          }
        />
        <TextWithRightMedia
          title={<h3>Retail Stores</h3>}
          media={<Image src={pranaRetailImage} />}
          is_full_bleed={true}
          cta_text={'Retail Store Openings'}
          cta_url={'https://columbiasportswearcompany.wd5.myworkdayjobs.com/prAna_Careers?source_id=csccom&source_p=careers_pra'}
          content={
            <>
            <Text>
              Join one of our store teams in Boulder or Portland. Both locations offer weekly events for the local community that focus on yoga, hiking, and climbing. 
            </Text>
            </>
          }
        />
      </Container>
    </Box>
  )
}

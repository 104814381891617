import React from 'react';
import { Button, Image, LinkBox, LinkOverlay, Box, Text, Icon, Stack, Container, Heading, SimpleGrid, Flex } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import HomeLayout from '../../../components/layouts/homelayout';
import Seo from '../../../components/sections/seo';
import PageHeader from '../../../components/sections/pageheader';

// import HeadingWithText from '../../../components/sections/headingwithtext';
import QuoteWithRightMedia from '../../../components/sections/quotewithrightmedia';
import GridCardNoLink from '../../../components/sections/gridcardnolink'

import aBanner from '../../../components/ui/regionalcareers/Asia_Hero.png'

import sivaKumar from '../../../components/ui/regionalcareers/asia/Siva-Kumar.jpg'
import bangladeshEmployees from '../../../components/ui/regionalcareers/asia/Bangladesh-Employees.jpg'
import taiwanEmployees from '../../../components/ui/regionalcareers/asia/Taiwan-Employees.jpg'

import asiaCard from '../../../components/ui/regionalcareers/asia/Asia-2.jpg'
import japanCard from '../../../components/ui/regionalcareers/asia/japan.jpg'
import chinaCard from '../../../components/ui/regionalcareers/asia/CHINA-1.jpg'
import koreaCard from '../../../components/ui/regionalcareers/asia/KOREA-7.jpg'
import hkCard from '../../../components/ui/regionalcareers/asia/Hong_Kong.jpg'
import bangladeshCard from '../../../components/ui/regionalcareers/asia/BANGLADESH-1.jpg' 
import indonesiaCard from '../../../components/ui/regionalcareers/asia/INDONESIA-1.jpg'
import vietnamCard from '../../../components/ui/regionalcareers/asia/VIETNAM-2.jpg'
import indiaCard from '../../../components/ui/regionalcareers/asia/INDIA.jpg'



export default function AsiaTabPanel() {
  return (
    <HomeLayout>
        <Seo
            title="Careers in Asia"
            description="Find your next job in our Asia locations."
            //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
        />
        <PageHeader 
            heading='Asia Region'
            bkgImg={aBanner}
        />
        <Container maxW={'6xl'} my={9}>
            <Box className='flow'>
                <Text>
                    The Asia Region is an area of expansion and growth representing 12 countries, 15 office locations, over 200 retail stores. Opportunities abound in manufacturing, sourcing, liaison offices, plus country headquarters in Tokyo, Seoul, Shanghai. Each location provides a rich culture and heritage of cross functional collaboration and employee development.
                </Text>
                <Stack my={10}>
                    <Flex 
                        minH={'375px'}
                        justify="center"
                        align="center"
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${asiaCard})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        borderRadius={10}
                        flexDir={'column'}
                    >
                        <Text
                            whiteSpace="pre-line"
                            align="center"
                            color="white"
                            fontWeight="bold"
                            fontFamily="GerTT"
                            fontSize="4xl"
                            opacity={1.0}
                            textTransform={'uppercase'}
                            sx={{'--flow-lh': 'lineHeights.10'}}
                            py={3}>Check Out Jobs in Asia
                        </Text>
                        <LinkBox as={'a'}>
                            <LinkOverlay
                                href='https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=careers_as'
                                isExternal
                                >
                                    <Button
                                        size="md"
                                        p={6}
                                        bg="black"
                                        color="white"
                                        borderRadius={8}
                                        mt={8}
                                        textTransform={'uppercase'}
                                        >
                                        Join the Team
                                        <Icon
                                            color="white"
                                            w={5}
                                            h={5}
                                            as={ExternalLinkIcon}
                                            pl="4px"
                                            ml={1}
                                        >
                                        </Icon>
                                    </Button>
                            </LinkOverlay>
                        </LinkBox>
                    </Flex>
                </Stack>
                <Heading textAlign={'center'} sx={{'--flow-space': 'space.16'}}>Our Asia Locations</Heading>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} my={12}>
                    <GridCardNoLink
                        title='Japan'
                        backgroundImage={japanCard}
                        href='#'
                        />
                    <GridCardNoLink
                        title='China'
                        backgroundImage={chinaCard}
                        href='#'
                        />
                    <GridCardNoLink
                        title='Korea'
                        backgroundImage={koreaCard}
                        href='#'
                        />
                    <GridCardNoLink
                        title='Hong Kong'
                        backgroundImage={hkCard}
                        href='#'
                        />
                    <GridCardNoLink
                        title='Bangladesh'
                        backgroundImage={bangladeshCard}
                        href='#'
                        />
                    <GridCardNoLink
                        title='Indonesia'
                        backgroundImage={indonesiaCard}
                        href='#'
                        />
                    <GridCardNoLink
                        title='Vietnam'
                        backgroundImage={vietnamCard}
                        href='#'
                        />
                    <GridCardNoLink
                        title='India'
                        backgroundImage={indiaCard}
                        href='#'
                        />
                </SimpleGrid>
                <QuoteWithRightMedia media={<Image borderRadius={10} src={sivaKumar} />} quote={`“Talent development and offering opportunities are simultaneous at Columbia. I came to Columbia with experience in training and factory management skills and these talents were identified by Columbia’s management team by offering me the opportunity to work on global projects.”`} speaker="– Siva Kumar, Production, India" />
                <Heading as="h3" textAlign={'center'} size={'lg'}>Life in Asia</Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <Box as='figure'>
                        <Image
                            src={bangladeshEmployees}
                            alt='Bangladesh employees celebrate'
                            objectFit={'cover'}
                            borderRadius={'10px'}
                            h={'300px'}
                            w={'540px'}
                        />
                        <Box as='figcaption'>
                            <Text
                                fontSize={"sm"}
                                mt={2}
                                >
                                Bangladesh employees celebrating our 80 years in business!
                            </Text>
                        </Box>
                    </Box>
                    <Box as='figure'>
                        <Image
                            src={taiwanEmployees}
                            alt='Taiwan employees volunteer'
                            objectFit={'cover'}
                            borderRadius={'10px'}
                            h={'300px'}
                            w={'540px'}
                        />
                        <Box as='figcaption'>
                            <Text
                                fontSize={"sm"}
                                mt={2}
                                >
                                Taiwan employees at a volunteer day
                            </Text>
                        </Box>
                    </Box>
                </SimpleGrid>
            </Box>
        </Container>
    </HomeLayout>
  );
}

import { Flex, Stack, Text } from '@chakra-ui/react';

export default function GridCardNoLink ({ title, backgroundImage }) {
  return (
    <Stack>
        <Flex sx={{ 'aspect-ratio': '16 / 9' }}
            w="100%"
            justify="center"
            align="center"
            bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${backgroundImage})`}
            bgSize="cover"
            bgRepeat="no-repeat"
        >
        <Text
            whiteSpace="pre-line"
            align="center"
            color="white"
            textTransform="uppercase"
            fontWeight="bold"
            fontFamily="GerTT"
            fontSize="xl"
            opacity={1.0}>{title}</Text>
        </Flex>
    </Stack>
  );
};

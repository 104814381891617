import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom'
import { Image, Tabs, TabList, TabPanels, TabPanel, Tab, Fade, Box } from '@chakra-ui/react';

import HomeLayout from '../../../components/layouts/homelayout';
import ColumbiaTabPanel from './columbiatabpanel'
import SorelTabPanel from './soreltabpanel'
import MhwTabPanel from './mhwtabpanel'
import PranaTabPanel from './pranatabpanel'
import colBanner from '../../../components/ui/ourbrands/columbia/job_opps_bkg_col.jpg';
import mhwBanner from '../../../components/ui/ourbrands/mhw/MHW__Careers_Header.png';
import pranaBanner from '../../../components/ui/ourbrands/prana/prana_banner.jpg';
import sorelBanner from '../../../components/ui/ourbrands/sorel/sorel_banner.jpg';

import pranaLogo from '../../../components/ui/brandlogos/prana_logo.png';
import colLogo from '../../../components/ui/brandlogos/columbia_logo.png';
import sorelLogo from '../../../components/ui/brandlogos/sorel_logo.png';

export default function OurBrands() {
  const tabMap = useMemo(() => new Map([
    [0, "tab-78baa3e833ecb8b09e4"],
    [1, "tab-9d37deaf387dfeb99f5"],
    [2, "tab-7f9133205962d06a60c"],
    [3, "tab-05cf9eb2b0103558eb1"]
  ]), []);

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = useCallback((index) => {
    setSearchParams(tabMap.get(index), { replace: true });
    setTabIndex(index)
  }, [tabMap, setSearchParams]);

  useEffect(() => {
    if (searchParams.has(tabMap.get(0))) {
      handleTabsChange(0);
    } else if (searchParams.has(tabMap.get(1))) {
      handleTabsChange(1);
    } else if (searchParams.has(tabMap.get(2))) {
      handleTabsChange(2);
    } else if (searchParams.has(tabMap.get(3))) {
      handleTabsChange(3);
    }
  }, [tabMap, searchParams, handleTabsChange])

  return (
    <HomeLayout align="center">
      <Tabs isFitted w="full" isLazy index={tabIndex} onChange={handleTabsChange}>
        <TabPanels>
          <TabPanel p={0}>
            <Fade in={true}>
            <Box
                className='page-banner'
                w={'full'}
                pos={'relative'}
                top={0}
                left={0}
                backgroundColor={'black'}
              >
                <Image
                  src={colBanner} 
                  pos={'relative'}
                  top={0}
                  left={0}
                  //opacity={'.9'}
                  alt='Columbia banner image'
                  w={'full'} 
                />
                <Image src={colLogo}
                  pos={'absolute'}
                  top={0}
                  left={{ base: '250px', md: '500px'}}
                  bottom={0}
                  right={0}
                  margin={'auto'}
                  width={'36%'}
                  alt='Columbia logo'
                />
              </Box>
            </Fade>
          </TabPanel>
          <TabPanel p={0}>
            <Fade in={true}>
            <Box
                className='page-banner'
                w={'full'}
                pos={'relative'}
                top={0}
                left={0}
                backgroundColor={'black'}
              >
                <Image
                  src={sorelBanner} 
                  pos={'relative'}
                  top={0}
                  left={0}
                  backgroundColor={'black'}
                  opacity={'.9'}
                  alt='SOREL banner image'
                  w={'full'} 
                />
                <Image src={sorelLogo}
                  pos={'absolute'}
                  top={0}
                  left={{ base: '75px', md: '150px'}}
                  bottom={0}
                  //right={0}
                  margin={'auto'}
                  width={'22%'}
                  alt='SOREL logo'
                />
              </Box>
            </Fade>
          </TabPanel>
          <TabPanel p={0}>
            <Fade in={true}>
              <Image src={mhwBanner} className='page-banner' w={'full'} />
            </Fade>
          </TabPanel>
          <TabPanel p={0}>
            <Fade in={true}>
              <Box
                className='page-banner'
                w={'full'}
                pos={'relative'}
                top={0}
                left={0}
                backgroundColor={'black'}
              >
                <Image src={pranaBanner}
                  pos={'relative'}
                  top={0}
                  left={0}
                  opacity={'.7'}
                  alt='prAna banner image'
                  w={'full'}
                />
                <Image src={pranaLogo}
                  pos={'absolute'}
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  margin={'auto'}
                  width={['35%', null, '40%', 'unset']}
                  alt='prAna logo'
                  />
              </Box>
            </Fade>
          </TabPanel>
        </TabPanels>

        <TabList>
          <Tab>Columbia</Tab>
          <Tab>SOREL</Tab>
          <Tab>Mountain Hardwear</Tab>
          <Tab>prAna</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
           <ColumbiaTabPanel />
          </TabPanel>
          <TabPanel>
            <SorelTabPanel />
          </TabPanel>
          <TabPanel>
            <MhwTabPanel />
          </TabPanel>
          <TabPanel>
            <PranaTabPanel />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </HomeLayout>
  );
}

import React from 'react';
import {
  Box,
  Container
} from '@chakra-ui/react';
import Seo from '../../components/sections/seo';
import Bio from '../../components/sections/bio';

// import BioWithRightMedia from '../../components/sections/biowithrightmedia';
// import PresidentPrana from '../../components/ui/ourcompany/leadership/tricia_shumavon_headshot.png'

export default function PranaTabPanel() {
  return (
    <Box>
      <Seo
        title="Leadership at prAna"
        description="Executive leadership at prAna."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
      <Container
        maxW={'80ch'}
        marginX={'auto'}
        paddingX={'clamp(1.09rem, 1rem + 0.47vw, 1.33rem)'}
        sx={{'--flow-lh': '1.6'}}
        marginY={'12'} 
        className='flow'
      >
        {/* <BioWithRightMedia
            title={'TRICIA SHUMAVON'}
            subTitle={'PRESIDENT, prAna'}
            bio={
                <Text>
                  Tricia Shumavon joined Columbia Sportswear Company in September 2023 as prAna brand President. Before joining prAna, Ms. Shumavon was the Global Vice President of Women’s, Men’s, and Kids sportswear apparel for Adidas in Europe. Tricia has vast experience in the sportswear industry, with a proven track record for innovating global brands with such companies as Nike, The Gap and L Brands. 
                </Text>
            }
            media={
              <Image borderRadius={10} src={PresidentPrana} />
            }
          /> */}

        <Bio
          name={'Tricia Shumavon'}
          title={<>President, <span className='text-unset'>prAna</span></>}
          bio={
            <p>
              Tricia Shumavon joined Columbia Sportswear Company in September 2023 as prAna brand President. Before joining prAna, Ms. Shumavon was the Global Vice President of Women’s, Men’s, and Kids sportswear apparel for Adidas in Europe. Tricia has vast experience in the sportswear industry, with a proven track record for innovating global brands with such companies as Nike, The Gap and L Brands.
            </p>
          }
        />
      </Container>
    </Box>
  );
}

import { Box, Flex, Text, IconButton, Image, Stack, VStack, Collapse, Icon, Link, Popover, PopoverTrigger, PopoverContent, useColorModeValue, useDisclosure, LinkBox, LinkOverlay, Spacer } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { FaGlobe } from 'react-icons/fa'

import navigationItems from './navigationitems.js'
import localeItems from './localeitems.js'

import cscLogo from '../../ui/csc-logo-white.png'
import colLogo from '../../ui/navigation/csclogo.png'
import mhwLogo from '../../ui/navigation/mhwlogo.png'
import sorLogo from '../../ui/navigation/sorlogo.png'
import praLogo from '../../ui/navigation/pralogo.png'

export default function Header() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box width="100%"
         as="header"
         position="fixed"
         zIndex={200}
         height='var(--site-header-height)'>
      <Flex
        bg="black"
        color="black"
        px={{ base: 4 }}
        alignItems={'center'}
        height='100%'>
        <LinkBox marginRight="20px">
          <LinkOverlay href="/">
            <Image
              width='124px'
              height='auto' 
              src={cscLogo}
              alt="Columbia Sportswear Company logo" />
          </LinkOverlay>
        </LinkBox>
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
          flexDir='row-reverse'
        >
          <IconButton
            color="white"
            onClick={onToggle}
            icon={ isOpen ? <CloseIcon w={8} h={8} /> : <HamburgerIcon w={8} h={8} /> }
            variant="ghost"
            aria-label="Toggle Navigation"
            _hover={{backgroundColor: 'whiteAlpha.500'}}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          justify={{ base: 'center', md: 'start' }}
          h="var(--site-header-height)"
          alignItems="center"
          flexDir='row-reverse'
          display={{ base: 'none', md: 'flex' }} >
          <Flex 
            as='nav' 
            display={{ base: 'none', md: 'flex' }} 
            ml={10}
            >
            <DesktopNav />
          </Flex>
          <Spacer />
          <Flex display="none" ml={10} alignItems="right">
            <LanguageNav />
          </Flex>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  return (
    <Stack as='ul' direction="row" spacing={4} className='site-nav'>
      {navigationItems.map((navItem) => (
        <Box as='li' key={navItem.label} className={navItem.label.replace(/ +/g, '-').toLowerCase()}>
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <Link
                className='site-nav__link'
                fontSize='var(--nav-font-size)'
                p={2}
                href={navItem.href ?? '#'}
                color="white"
                isExternal={navItem.isExternal}
                _hover={{
                  textDecoration: 'none',
                  color: '#0693e3'
                }}>
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {(navItem.groups || navItem.children) && (
              <PopoverContent
                border={0}
                boxShadow="xl"
                bg="white"
                p={4}
                minW={{ base: "100%", lg: "max-content" }}>
                  <Box as='ul' className='site-nav__sub'>
                    {navItem.groups && (
                    <VStack>
                      {navItem.groups.map((group) => (
                        <DesktopSubNav key={ group.label } {...group} />
                      ))}
                    </VStack>)}
                    {navItem.children && (
                      navItem.children.map((child) => (
                          <DesktopSubNav key={ child.label } {...child} />)))}
                  </Box>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const navLogo = (logo) => {
  switch (logo) {
    case 'colLogo':
      return colLogo;
    case 'mhwLogo':
      return mhwLogo;
    case 'sorLogo':
      return sorLogo;
    case 'praLogo':
      return praLogo;
    default:
      return;
  }
}

const DesktopSubNav = ({ label, href, subLabel, isExternal, logo, children }) => {
  return (
    <Stack as='li'>
      {href ? (
      <Link
        href={href}
        role="group"
        display="block"
        className='site-nav__link'
        fontSize='var(--nav-font-size)'
        py={'var(--space-2xs)'}
        px={'var(--space-3xs)'}
        rounded="md"
        isExternal={isExternal}
        _hover={{ textDecoration: "none" }}>
        <Stack direction="row" align="left">
          {logo && <Image src={navLogo(logo)} boxSize="20px"/>}
          <Box>
            <Text
              transition={'all .3s ease'}
              _groupHover={{ color: '#0693e3' }}
              fontWeight={500}>
              {label}
            </Text>
            {subLabel && <Text>{subLabel}</Text>}
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            flex={1}>
            {isExternal ? <Icon color={'#0693e3'} w={5} h={5} as={ExternalLinkIcon} />
              : <Icon color={'#0693e3'} w={5} h={5} as={ChevronRightIcon} />}
          </Flex>
        </Stack>
      </Link> ) : (
          <Text
            textTransform={'uppercase'}
            fontWeight={'bold'} 
            fontSize={'var(--step--2)'} 
            color={'gray.600'}
            marginTop={'var(--space-s)'}
            lineHeight={1}
            borderTop={'1px solid var(--chakra-colors-gray-300)'}
            paddingTop={'var(--space-2xs)'}
          >
            {label}
          </Text> 
        )} {children && (
      <Stack as='ul' listStyleType={'none'}>

          {children.map((child) => (
            <DesktopChildrenNav key={child.label} {...child} />
          ))}

      </Stack>)}
    </Stack>
  );
};

const DesktopChildrenNav = ({ label, href, subLabel, isExternal }) => {
  return (
    <Stack as='li'>
      <Link
      href={href}
      role={'group'}
      display={'block'}
      py={'4px'}
      px={'var(--space-3xs)'}
      _hover={{ textDecoration: "none" }}
      isExternal={isExternal}
      >
        <Stack direction="row" align="left">
        <Text as='span'
          transition={'all .3s ease'}
          fontSize='var(--nav-font-size)'
          _groupHover={{ color: '#0693e3' }}>
          {label}
        </Text>
        <Text as='span' fontSize='var(--nav-font-size)'>{subLabel}</Text>
        <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            flex={1}>
            {isExternal ? <Icon color={'#0693e3'} w={5} h={5} as={ExternalLinkIcon} />
              : <Icon color={'#0693e3'} w={5} h={5} as={ChevronRightIcon} />}
          </Flex>
          </Stack>
      </Link>
    </Stack>

  );
};

const MobileNav = () => {
  return (
    <Stack as='ul'
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}>
      {navigationItems.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack as='li' spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>

          <Stack as='ul'
            className='mobile-nav'
            gap={3}
            mb={4}
            pl={4}
            borderLeft={1}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            align={'start'}>
            {children &&
              children.map((child) => (
                <MobileChildrenNav key={child.label} {...child} />
              ))} 
            
          </Stack>
      </Collapse>
    </Stack>
  );
};

const MobileChildrenNav = ({ label, children, href }) => {
  return (
    <Box as='li'>
      {href ? (<Link key={label} href={href}>{label}</Link>) : 
      (<Text key={label} textTransform={'uppercase'} fontWeight={'bold'} fontSize={'var(--step--1)'} color={'gray.600'} mt={'var(--space-xs)'} mb={'var(--space-3xs)'}>{label}</Text>)}
      
      {children ? (
          <Stack as='ul'>
          {children.map((child) =>(
            <Stack as='li'>
              <Link href={child.href}>{child.label}</Link>
            </Stack>
        ))}
        </Stack>
      ) : (
        null
      )
      }
    </Box> 
  );
  
};

const LanguageNav = () => {
  return(
    <Box
      p={2}
      _hover={{
        textDecoration: 'none',
        color: '#0693e3'
      }}>
      <Stack direction="row" align="center">
        <Popover trigger=" hover" placement="bottom-start">
          <PopoverTrigger>
            <Link
              p={2}
              color="white"
              _hover={{
                textDecoration: 'none',
                color: '#0693e3'
              }}>
              <Stack direction="row" align="center">
                <Text>{localeItems.label}</Text>
                <FaGlobe/>
              </Stack>
            </Link>
          </PopoverTrigger>
          <PopoverContent
            border={0}
            boxShadow="xl"
            bg="white"
            p={4}
            rounded="xl"
            minW={{ base: "100%", lg: "max-content" }}>
            {localeItems.children && (
              localeItems.children.map((child) => (
                <DesktopSubNav key={child.label} {...child} />)))}
          </PopoverContent>
        </Popover>
      </Stack>
    </Box>
  );
};
